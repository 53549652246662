import React from 'react';
import PostBody from './postbody';
import Img from 'gatsby-image';
import PostLayout from './postlayout';
import Date from './date';
import PostType from '../types/post';

// type Props = {
//   post: PostType;
//   morePosts: PostType[];
//   preview?: boolean;
// };

const BlogPost = (props: PostType) => {
  const { author, title, coverImage, date, _rawContent } = props;

  return (
    <PostLayout>
      <div className="max-w-screen-lg mx-auto px-4 md:px-6">
        <div className="mx-auto text-center font-bold text-black mb-8">
          <h1 className="text-4xl mb-3">{title}</h1>
          <span className="text-sm font-normal space-x-2">
            <Date dateString={date} />
            <span>|</span>
            <span>{author.name}</span>
          </span>
        </div>
        <div className="bg-white shadow-lg rounded-md">
          <Img
            fluid={coverImage.asset.fluid}
            className="rounded-t-md w-full"
            alt={coverImage.alt}
          />
          <PostBody content={_rawContent} />
        </div>
      </div>
      {/* <div>
          {morePosts.map((post) => (
            <div key={title}>{title}</div>
          ))}
        </div> */}
    </PostLayout>
  );
};

export default BlogPost;
