import React from 'react';
import { graphql } from 'gatsby';
import BlogPost from '../components/blogpost';
import { Layout } from '../components';
import SEO from '../components/seo';
import GraphQLErrorList from '../components/graphqlerrorlist';
import PostType from '../types/post';

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      category {
        _id
        title
      }
      coverImage {
        asset {
          _id
          fluid(maxWidth: 900) {
            ...GatsbySanityImageFluid
          }
        }
        alt
      }
      title
      slug {
        current
      }
      _rawContent
      content {
        _key
        _type
        children {
          _key
          _type
          text
        }
        style
      }
      author {
        _key
        name
        # picture {
        #   asset {
        #     _id
        #     fluid(maxWidth: 200) {
        #       ...GatsbySanityImageFluid
        #     }
        #   }
        # }
      }
    }
  }
`;

type BlogPostTemplateProps = {
  data: {
    post: PostType;
  };
  errors: any[];
};

const BlogPostTemplate = (props: BlogPostTemplateProps) => {
  const { data, errors } = props;
  const post = data && data.post;

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {post && (
        <SEO
          title={post.title || 'Untitled'}
          image={post.coverImage.asset.fluid.src}
        />
      )}

      {errors && <GraphQLErrorList errors={errors} />}

      {post && <BlogPost {...post} />}
    </Layout>
  );
};

export default BlogPostTemplate;
