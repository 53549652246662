import React from 'react';
// import Alert from './alert';

export interface PostLayoutProps {
  preview?: boolean;
  children?: React.ReactNode;
}

const PostLayout: React.FC<PostLayoutProps> = ({ children }) => {
  return (
    <section className="page-bg py-16 h-full w-full flex-1 border-t border-gray-300">
      {children}
    </section>
  );
};

export default PostLayout;
