import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import markdownStyles from './markdown.module.css';
interface PostProps {
  content: any;
}

export default function PostBody({ content }: PostProps) {
  return (
    <div className="mx-auto p-8">
      <BlockContent className={markdownStyles.markdown} blocks={content} />
    </div>
  );
}
